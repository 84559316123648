<template>
  <div class="px-10">
    <div class="text-h4 primary--text">ค่าใช้จ่าย รายจ่ายภาษีรถ</div>
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <div style="width: 200px">
        <DatePicker
          label="วันที่"
          :value="filter.start_date"
          @onChange="(val) => (filter.start_date = val)"
          hideDetails
          outlined
          rounded
          dense
        />
      </div>

      <div style="width: 200px" class="mx-10">
        <DatePicker
          label="ถึงวันที่"
          :value="filter.end_date"
          @onChange="(val) => (filter.end_date = val)"
          hideDetails
          outlined
          rounded
          dense
        />
      </div>

      <v-btn
        color="primary"
        outlined
        small
        class="ml-5 px-10"
        @click="getData()"
        >ค้นหา</v-btn
      >

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      class="elevation-2 cursor-pointer"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      sort-by="col_a"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <!-- <template slot="item._id" slot-scope="props">
          {{ ((filter.page-1) * filter.item_per_page) + props.index + 1 }}
        </template> -->

      <template v-slot:[`item.payment_type`]="{ item }">
        {{ item.payment_type == 1 ? 'เงินสด' : '' }}
        {{ item.payment_type == 2 ? 'โอน' : '' }}
      </template>

      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>

      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0') }}
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="grey" small outlined rounded @click="edit(item)">ดู</v-btn>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.car_count`]="{ item }">
        {{ item.car_count | numeral('0,0') }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    DatePicker,
  },

  data: () => ({
    dialogUpdate: false,
    search: '',
    selected: [],
    loading: true,
    pageCount: 0,
    list: [],
    headers: [
      {
        text: 'ลำดับ',
        align: 'center',
        sortable: false,
        value: '_id',
        filterable: false,
      },
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'รอบภาษี', value: 'project_name' },
      { text: 'จำนวน (คัน)', value: 'car_count', filterable: false },
      { text: 'ช่องทางชำระ', value: 'payment_type', filterable: false },
      { text: 'รวมราคาขนส่งเก็บ', value: 'net_price', filterable: false },
      { text: '', value: 'actions', filterable: false },
    ],
    filter: {
      branch_id: '',
      payment_type: '',
      item_per_page: 10,
      page: 1,
      category_id: '',
      start_with: '',
      sub_category_id: '',
      start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      // month: moment().format("YYYY-MM")
    },
  }),

  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },

  methods: {
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_extension_tax_expense`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },

    edit(item) {
      this.$router.push({
        name: 'expenses-cartax-update',
        params: { id: item._id },
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { expense_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/delete_expense`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
  },
};
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>